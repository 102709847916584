import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BRIEF_API_URL, DEFAULT_SUBDOMAIN } from 'constants/global';
import { Notifications } from 'constants/Notifications';

import _ from 'lodash';
import LocalStorage from './LocalStorage';
import { getParameterByName, getSubdomainFromHost } from './server/ServerHelpers';

export interface ResponseService {
  success: boolean;
  headers: {
    'set-cookie'?: string[];
    [prop: string]: any;
  };
  data: any;
}
interface ResponseError {
  success: boolean;
  status: number;
  error: {
    code: any;
    message: string;
    name: string;
    status: number;
  };
}

const LOCAL_DEBUG = false;
const API_DEBUG = false;

console.info('API_URL', BRIEF_API_URL);

interface ServerError {
  name: any;
  title?: string;
  message: any;
  code: number;
  status: number;
  errors?: string | any[];
  error?: string;
  type: string;
  hideError?: boolean;
}

Axios.defaults.withCredentials = true;

const default_config = (): AxiosRequestConfig => ({
  method: 'GET',
  baseURL: BRIEF_API_URL,
  timeout: LOCAL_DEBUG ? 3 * 60 * 1000 : 20 * 1000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  // @ts-ignore
  //credentials: "same-origin",
  withCredentials: true,
  responseType: 'json',
  //maxRedirects: 1,
  validateStatus(status) {
    return status < 400; // Reject only if the status code is greater than or equal to 400
  }
});
export const API = Axios.create(default_config());

// Add a request interceptor
API.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  (response) => {
    // Do something with response data
    if (response?.status !== 200 || response?.data?.success === false || !response?.data?.success) {
      if (API_DEBUG) {
        debugger;
      }
      return Promise.reject(response?.data || null);
    } else {
      const returnData: any = {
        data: _.get(response, 'data'),
        headers: response.headers,
        success: true
      };
      return Promise.resolve(returnData);
    }
  },
  (error: AxiosError) => {
    // Do something with response error
    if (API_DEBUG) {
      debugger;
    }
    showErrorMessage(error?.response?.data);

    return Promise.reject(error?.response?.data);
  }
);

export const API_CALL = async (config: AxiosRequestConfig) => {
  const axiosConfig = _.merge(default_config(), config);
  if (API_DEBUG) {
    debugger;
  }
  if (axiosConfig.params) {
    axiosConfig.params = formatParams(axiosConfig.params);
  }

  //CHECK AUTH
  // const headers = LocalStore.get("headers")

  // if (headers) {
  //   axiosConfig.headers = _.merge(axiosConfig.headers, headers);

  // }

  if (process?.browser) {
    try {

      let subdomain = getSubdomainFromHost(window.location.host);
      if (!subdomain) {
        subdomain = getParameterByName('subdomain');
      }


      axiosConfig.headers = _.merge(axiosConfig.headers, { subdomain });


      if (window?.location && window?.location.origin.includes('localhost')) {
        const subdomain = LocalStorage.getItem('subdomain');
        const Authorization = 'Bearer ' + LocalStorage.getItem('Authorization');
        // const deviceToken = LocalStorage.getItem('deviceToken');
        axiosConfig.headers = _.merge(axiosConfig.headers, { Authorization, subdomain });
      }
    } catch (e) {
      console.warn(e);
    }
  }
  // if (includeToken(axiosConfig)) {
  //   let { accessToken = defaultAccessToken } = await getGlobalUser() || {}
  //   debugger;
  //   if (accessToken) {
  //     if (axiosConfig.baseURL?.includes("upload/image")) {
  //       if (!axiosConfig.params) axiosConfig.params = {}
  //       axiosConfig.params.accessToken = accessToken;
  //     } else {
  //       if (!axiosConfig.data) axiosConfig.data = {}
  //       axiosConfig.data.accessToken = accessToken;
  //     }

  //   }
  //}

  const urlCalled = `${axiosConfig.baseURL}${axiosConfig.url || ''}  `;
  console.info(
    'API_CALL',
    `${axiosConfig.method} => ${urlCalled} `,
    //{ headers: axiosConfig.headers },
    axiosConfig.params,
    axiosConfig.data
  );
  // console.info('axiosConfig', axiosConfig)

  return API(axiosConfig)
    .then(async (response: any) => {
      console.info('API_SUCCESS => ' + urlCalled, response);
      if (API_DEBUG) {
        debugger;
      }

      return response.data || response;
    })
    .catch((err) => {
      console.info('axiosConfig', axiosConfig);
      console.warn('ERROR', err);
      if (API_DEBUG) {
        debugger;
      }
      showErrorMessage(err);
      return Promise.reject(err);
    });
};

export const showErrorMessage = async (error: ResponseError) => {


  if (API_DEBUG) debugger;

  const { code, name, message } = error?.error || {};

  if (API_DEBUG) debugger;

  console.error(error?.error);
  //alert(`ERROR CODE ${ code } : ${ description } `)
  if (LocalStorage.getItem('Authorization')) {
    Notifications({
      type: 'error',
      message: `Server Error code ${code} - ${name}`,
      description: message
    });
  }
};

const formatParams = (params: any) => {
  console.info('formatParams', params);

  params = _.pickBy(params, _.identity);
  if (params.pagination) {
    // page: 1,
    //   count : 0,
    //     perPage : 20,
    //       total : 0
  }
  return params;
};

export default API_CALL;

const includeToken = (axiosConfig: AxiosRequestConfig) => {
  const excludedRoutes = [
    'loginCredentials',
    'loginAccessToken',
    'changePassword',
    'sendRecoverLink',
    'registration',
    'publics'
  ];

  if (axiosConfig?.params?.route) {
    if (_.some(excludedRoutes, (el) => _.includes(axiosConfig?.params?.route, el))) {
      return false;
    }
  }

  if (_.some(excludedRoutes, (el) => _.includes(axiosConfig.baseURL, el))) {
    return false;
  }

  return true;
};
export function getResponse<T>(response, prop: string, defaultVal: any = false): T {
  return response?.data?.[prop] ?? response?.[prop] ?? defaultVal;
}
